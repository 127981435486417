import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../../components/Select/Select';
import {
  DEFAULT_STATUS_CONDITIONS,
  ROUTING_STATUS_OPTIONS,
  ROUTING_RELATIONAL_OPERATOR_OPTIONS,
} from '../../constants';
import DurationInputs from '../../components/DurationInputs';

const DialogStatusInputs = ({ orCondition, onKeyChange, onValueChange }) => {
  const duration = Object?.values(orCondition)[0]?.duration ?? 0;
  const operator = Object?.values(orCondition)[0]?.operator ?? ROUTING_RELATIONAL_OPERATOR_OPTIONS[0].value;

  const defaultValue = useMemo(() => {
    const keys = Object.keys(orCondition);
    // получаем ключ условия
    if (keys.length > 0) {
      const key = keys[0];
      // получаем тело условия
      if (key) {
        const condition = orCondition[key];
        // находим option по значению условия и его группе
        if (condition) {
          const data = ROUTING_STATUS_OPTIONS.find((o) => o.group === key && o.value === condition.value);
          
          if (data) {
            return data;
          }
        }
      }
    }
    return ROUTING_STATUS_OPTIONS[0];
  }, [orCondition]);

  const handleSelect = useCallback((e) => {
    const key = e.target.value.key;
    const value  = DEFAULT_STATUS_CONDITIONS[key];

    onKeyChange(key, value);
  }, [onKeyChange])
  
  return (
    <>
      <Select
        isFullWidth
        sx={{ maxWidth: '220px' }}
        label="Статус"
        options={ROUTING_STATUS_OPTIONS}
        defaultValue={defaultValue}
        onChange={handleSelect}
      />
      <Select
        isFullWidth
        sx={{ maxWidth: '180px' }}
        label="Прошло времени"
        options={ROUTING_RELATIONAL_OPERATOR_OPTIONS}
        defaultValue={
          ROUTING_RELATIONAL_OPERATOR_OPTIONS.find(
            (o) => o.value === operator,
          ) ?? ROUTING_RELATIONAL_OPERATOR_OPTIONS[0]
        }
        onChange={(e) => onValueChange('operator', e.target.value.value)}
      />
      <DurationInputs
        inputStyles={{ maxWidth: '110px', minWidth: '80px' }}
        duration={duration}
        onChange={(d) => onValueChange(d.key, d.value)}
      />
    </>
  );
};
DialogStatusInputs.propTypes = {
  orCondition: PropTypes.object,
  onKeyChange: PropTypes.func,
  onValueChange: PropTypes.func,
};
DialogStatusInputs.defaultProps = {
  orCondition: {},
  onKeyChange: null,
  onValueChange: null,
};

export default DialogStatusInputs;
