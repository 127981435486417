/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Route, useHistory, useLocation } from 'react-router-dom';
import {
  clearCountStat,
  setDialogStatusFilter,
  setSelectedProviders,
} from 'store/actions';

import { Page } from '../../components';
import {
  ConversationDetails,
  ConversationPlaceholder,
  ConversationSearchPanel,
  ConversationGallery,
  ConversationList,
} from './components';
import * as chatActions from '../../store/actions/chatActions';
import * as sessionActions from '../../store/actions/sessionActions';
import * as templateActions from '../../store/actions/templateActions';
import * as chatSelectors from '../../store/selectors/chatSelectors';
import { showModal } from '../../store/actions/modalActions';
import { getChatPosts } from '../../store/actions/postActions';
import { checkAiSync } from '../../store/actions/aiActions';
import { setUserId } from '../../store/actions/settingsActions'
import { fetchSingInByCode } from '../../store/actions/sessionActions'
 
import {
  DEFAULT_DIALOGS_PAGE_SIZE,
  DEFAULT_DIALOG_MESSAGES_PAGE_SIZE,
} from '../../config';
import { PostForm, PostListPreview } from './components/Post';
import PostSearchPanel from './components/Post/PostSearchPanel/PostSearchPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 67px)',
    display: 'flex',
    overflow: 'hidden',
    '@media (max-width: 960px)': {
      height: 'calc(100% - 2px)',
      '& $conversationList, & $conversationDetails, & $conversationListPost': {
        flexBasis: '100%',
        width: '100%',
        maxWidth: 'none',
        flexShrink: '0',
        transform: 'translateX(0)',
      },
    },
  },
  conversationList: {
    width: 300,
    flexBasis: 300,
    flexShrink: 0,
    '@media (min-width: 960px)': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  conversationListPost: {
    flex: 1,
    width: 300,
    flexShrink: 0,
    '@media (min-width: 960px)': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  menuWrap: {
    padding: '10px 12px',
    borderRight: '2px solid #EAEAEA',
    backgroundColor: 'transparent',
  },
  menu: {
    display: 'flex',
    borderRadius: '10px',
    backgroundColor: '#EBEEF3',
    padding: '4px',
  },
  menuItem: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 5px 11px',
    gap: '10px',
    fontSize: '14px',
    lineHeight: '15px',
    cursor: 'pointer',
    color: '#636A74',
  },
  menuItemSelected: {
    borderRadius: '6px',
    background: '#006AE4',
    color: 'white',
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Chat = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const { search } = useLocation();
  const query = useQuery();
  const shouldShowStartDialog = query.get('startDialog');
  const CRMFilterUsersById = query.get('CRMFilterUsersById');
  const isAmoCRM = query.get('isAmoCRM');
  const dialogView = query.get('dialogView');
  const [page, setPage] = useState(1);
  const [postPage, setPostPage] = useState(1);
  const connection = useSelector((state) => state.ws.connection);
  const searchedDialogs = useSelector((state) => state.chat.searchedDialogs);
  // eslint-disable-next-line no-unused-vars
  const dialogList = useSelector(chatSelectors.selectDialogsMemorized);
  const dialogsPagination = useSelector(
    (state) => state.chat.dialogsPagination,
  );
  const selectedCompany = useSelector((state) => state.session.selectedCompany);
  const selectedProviders = useSelector(
    (state) => state.settings.selectedProviders,
  );
  const assigneeFilter = useSelector((state) => state.settings.assigneeFilter);
  const customFilter = useSelector((state) => state.settings.customFilter);
  const postMode = useSelector((state) => state.settings.postMode);
  const dialogStatusFilter = useSelector(
    (state) => state.settings.dialogStatusFilter,
  );
  const currentDialog = useSelector((state) => state.session.currentDialog);
  const shouldOpenDialog = useSelector((state) => state.chat.shouldOpenDialog);
  const dateFilter = useSelector((state) => state.settings.dateFilter);

  const getPosts = (newPage) => {
    const req = {
      uuidCompany: selectedCompany,
      page: newPage,
      size: DEFAULT_DIALOGS_PAGE_SIZE,
      historySize: DEFAULT_DIALOG_MESSAGES_PAGE_SIZE,
      sort: 'desc',
      fields: [],
    };

    getChatPosts(req)(dispatch);
  };
  useEffect(() => {
    const authPath = history?.location?.state?.from?.pathname;
    const redirectPath = history?.location?.state?.from?.state?.from?.pathname;
    if (authPath === '/auth/login' && redirectPath?.includes('/chat/')) {
      window.location.href = redirectPath;
    }
  }, []);

  const clearPhone = (phoneString = '') => {
    if (typeof phoneString !== 'string') return phoneString;
    const phone = decodeURIComponent(phoneString)
      .replace(/(\s|\t|\+|\-|\(|\))/g, '')
      .trim();
    const isPhone = /^\d+$/.test(phone);
    if (isPhone) {
      return phone[0] === '8' && phone.length === 11
        ? '7' + phone.slice(-10)
        : phone;
    }
    return phoneString;
  };

  const getDialogs = (newPage) => {
    const filter = !dialogView
      ? {
          favoritesFilter: customFilter
            ? Object.keys(customFilter)
                .map((x) => x)
                .join('')
            : '',
          connection:
            selectedProviders && selectedProviders.length
              ? selectedProviders[0]
              : null,
          participant: assigneeFilter.assignee,
          dateRange: dateFilter,
          uuidMessengerId: clearPhone(CRMFilterUsersById),
        }
      : {
          uuidMessengerId: clearPhone(CRMFilterUsersById),
          connection: null,
          favoritesFilter: '',
        };

    const getDialogsReqData = {
      // TODO: change to uuid company
      uuidCompany: selectedCompany,
      page: newPage,
      size: DEFAULT_DIALOGS_PAGE_SIZE,
      sort: 'desc',
      historySize: 1,
      filter,
    };

    if (dialogStatusFilter !== null) {
      getDialogsReqData.status = [dialogStatusFilter];
    }

    chatActions.getChatDialogs(getDialogsReqData)(dispatch);
  };
  useEffect(() => {
    // сброс "Выбранные мессенджеры" на "Все диалоги"
    if (dialogView) {
      dispatch(setSelectedProviders([]));
      dispatch(setDialogStatusFilter('open'));
     
    }
  }, []);

 
  const code = query.get('code');

  useEffect(() => {
    if (connection.status === 'connected') {
      sessionActions.getDepartaments(selectedCompany)(dispatch);
    }
  }, [connection]);
 

  const fetchData = () => {
    if (!selectedCompany) return;
      chatActions.fetchCompanyProviders({ uuidCompany: selectedCompany })(dispatch, store.getState);
      templateActions.fetchTemplates({ uuidCompany: selectedCompany })(dispatch, store.getState);

    if (connection.status === 'connected') {
      getDialogs(1);
      getPosts(1);
      dispatch(clearCountStat());
      dispatch(chatActions.getDialogsCount(selectedCompany));
    }

    if (shouldShowStartDialog === 'true') {
      dispatch(showModal('StartDialog'));
    }
  };

  useEffect(() => {
    fetchData();
 
}, [selectedCompany]);

  useEffect(() => {
    if (connection.status === 'connected') {
      dispatch(checkAiSync(selectedCompany));
    }
  }, [connection, selectedCompany]);

  useEffect(() => {
    if (connection.status === 'connected') {
      getDialogs(1);
      getPosts(1);
      dispatch(clearCountStat());
      dispatch(chatActions.getDialogsCount(selectedCompany));
      dispatch(setUserId(CRMFilterUsersById));
    }
  }, [
    connection,
    selectedProviders,
    assigneeFilter,
    dialogStatusFilter,
    customFilter,
    dateFilter,
  ]);

  useEffect(() => {
    // Обработчик события message на iframe
    const handleMessage = (event) => {
      // Проверяем, что это ответ на наш запрос
      if (event.data.action === 'baseUrlResponse') {
        // Обрабатываем полученные данные
        dispatch(chatActions.setAmoBaseUrl(event.data.baseUrl));

        window.parent.postMessage(
          { action: 'fetchContacts' },
          event.data.baseUrl,
        );
      } else if (event.data.action === 'fetchContactsResponse') {
        const responseContacts = event.data.contacts;
        if (responseContacts) {
          responseContacts.sort((a, b) => b.date - a.date);
          dispatch(chatActions.updatePersonesName(responseContacts));
        }
      }
    };
    if (isAmoCRM && dialogList.length > 0) {
      window.parent.postMessage(
        {
          action: 'baseUrl',
        },
        '*',
      );

      window.addEventListener('message', handleMessage);
    }
    // очистка события после размонтирования компонента
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [JSON.stringify(dialogList)]);

  useEffect(() => {
    if (shouldOpenDialog) {
      dispatch(sessionActions.setCurrentDialog(shouldOpenDialog));
      history.push(`/chat/${shouldOpenDialog}${search}`);
      dispatch(chatActions.openDialog(null));
    }
  }, [shouldOpenDialog]);

  return (
    <>
      {!postMode ? <ConversationSearchPanel /> : <PostSearchPanel />}
      <Page
        className={clsx({
          [classes.root]: true,
          [classes.openConversion]: Boolean(currentDialog),
        })}
        title="IntellectDialog"
      >
        {!postMode ? (
          <ConversationList
            className={classes.conversationList}
            conversations={
              _.isEmpty(searchedDialogs) ? dialogList : searchedDialogs
            }
            page={page}
            size={DEFAULT_DIALOGS_PAGE_SIZE}
            count={dialogsPagination.count || 0}
            onLoadMore={() => {
              if (_.isEmpty(searchedDialogs)) {
                const newPage = page + 1;
                setPage(newPage);
                getDialogs(newPage);
              }
            }}
          />
        ) : (
          <PostListPreview
            onLoadMore={() => {
              const newPage = postPage + 1;
              setPostPage(newPage);
              getPosts(newPage);
            }}
            className={classes.conversationListPost}
          />
        )}
        <Route exact path="/chat" component={ConversationPlaceholder} />
        <Route exact path="/chat/:uuid" component={ConversationDetails} />
        <Route exact path="/chat/post" component={PostForm} />
        <Route exact path="/chat/post/:uuid" component={PostForm} />
        <ConversationGallery />
      </Page>
    </>
  );
};

export default Chat;
