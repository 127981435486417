import { HOST } from '../../config';
import { apiGet, apiPost } from '../../api';
import { wsSndMessage } from './wsActions';

import {
  fetchAllPersonTags,
  fetchAllTags,
  setUserSettings,
} from './chatActions';

export const A = {
  REQUEST_SIGNIN: 'REQUEST_SIGNIN',
  RECEIVE_SIGNIN: 'RECEIVE_SIGNIN',
  ERROR_SIGNIN: 'ERROR_SIGNIN',

  REQUEST_REFRESH_TOKEN: 'REQUEST_REFRESH_TOKEN',
  RECEIVE_REFRESH_TOKEN: 'RECEIVE_REFRESH_TOKEN',
  ERROR_REFRESH_TOKEN: 'ERROR_REFRESH_TOKEN',

  SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
  LOGOUT: 'LOGOUT',

  REQUEST_GET_USER_INFO: 'REQUEST_GET_USER_INFO',
  RECEIVE_GET_USER_INFO: 'RECEIVE_GET_USER_INFO',
  ERROR_GET_USER_INFO: 'ERROR_GET_USER_INFO',

  SET_USER_INFO: 'SET_USER_INFO',
  SET_COMPANY: 'SET_COMPANY',
  SET_COMPANIES: 'SET_COMPANIES',

  SET_CURRENT_DIALOG: 'SET_CURRENT_DIALOG',

  UPDATE_DEPARTMENTS: 'UPDATE_DEPARTMENTS',

  EMAIL_UPDATE: 'EMAIL_UPDATE',
 


};





export const setAuthToken = (data) => ({
  type: A.SET_AUTH_TOKEN,
  data,
});

export const setUserInfo = (data) => ({
  type: A.SET_USER_INFO,
  data,
});

export const setCompany = (uuid) => ({
  type: A.SET_COMPANY,
  uuid,
});

export const setUserCompanies = (companies) => ({
  type: A.SET_COMPANIES,
  payload: companies,
});

const requestGetUserInfo = () => ({
  type: A.REQUEST_GET_USER_INFO,
});

const receiveGetUserInfo = (data) => ({
  type: A.RECEIVE_GET_USER_INFO,
  data,
});

const errorGetUserInfo = (err) => ({
  type: A.ERROR_GET_USER_INFO,
  err,
});

export const emailUpdate = (email) => ({
  type: A.EMAIL_UPDATE,
  email,
});

export const fetchUserCompanies = () =>  (dispatch, getState) =>
  apiGet({
    dispatch,
    url: `${HOST}/user/companies`,
    tokens: getState().session.tokenData,
    callback:(res) => {
      dispatch(setUserCompanies(res));
      const companies = res.map((el) => el.uuid);
      const { selectedCompany } = getState().session;
      if (companies.length && !companies.includes(selectedCompany)) {    
        const newCompany = companies[0];
        dispatch(setCompany(newCompany));
      }
    },
    error: (err) => {
      dispatch(errorGetUserInfo(err));
    },
  });

  

export const fetchGetUserInfo = () => async (dispatch, getState) => {
  dispatch(requestGetUserInfo());
  return apiGet({
    dispatch,
    url: `${HOST}/user/info`,
    tokens: getState().session.tokenData,
    callback: (res) => {
      dispatch(receiveGetUserInfo(res));
      dispatch(setUserInfo(res));
      dispatch(fetchUserCompanies());
      dispatch(fetchAllTags());
      dispatch(fetchAllPersonTags());
      dispatch(setUserSettings(res.settings));
    },
    error: (err) => {
      dispatch(errorGetUserInfo(err));
    },
  });
};

const requestSignIn = () => ({
  type: A.REQUEST_SIGNIN,
});

const receiveSignIn = (data) => ({
  type: A.RECEIVE_SIGNIN,
  data,
});

export const errorSignIn = (err) => ({
  type: A.ERROR_SIGNIN,
  err,
});

export const fetchSignIn = (data) => (dispatch) => {
  dispatch(requestSignIn());
  return new Promise((resolve, reject) => {
    apiPost({
      url: `${HOST}/auth/token`,
      body: data,
      callback: (res) => {
        dispatch(receiveSignIn(res));
        dispatch(setAuthToken(res));
        dispatch(fetchGetUserInfo());
        resolve();
      },
      error: (err) => {
        dispatch(errorSignIn(err));
        reject(err);
      },
    });
  });
};

const requestRefreshToken = () => ({
  type: A.REQUEST_REFRESH_TOKEN,
});

const receiveRefreshToken = (data) => ({
  type: A.RECEIVE_REFRESH_TOKEN,
  data,
});

const errorRefreshToken = (err) => ({
  type: A.ERROR_REFRESH_TOKEN,
  err,
});

export const fetchRefreshToken = () => (dispatch, getState) => {
  dispatch(requestRefreshToken());
  return apiPost({
    dispatch,
    url: `${HOST}/auth/token`,
    body: {
      grantBy: 'refresh',
      refresh: getState().session.tokenData.refresh.token,
      secret: process.env.REACT_APP_API_SECRET,
    },
    callback: (res) => {
      dispatch(receiveRefreshToken(res));
      document.location.reload();
    },
    error: (err) => {
      dispatch(errorRefreshToken(err));
    },
  });
};

export const logout = () => (dispatch) => {
  dispatch({
    type: A.LOGOUT,
  });
};


const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  export const fetchSingInByCode = (code) => async (dispatch) => {
    try {
      await sleep(3000);
      await dispatch(logout());
      dispatch(requestSignIn());
  
      const res = await new Promise((resolve, reject) => {
        apiPost({
          url: `${HOST}/auth/token`,
          body: {
            grantBy: 'code',
            code,
            secret: process.env.REACT_APP_API_SECRET,
          },
          callback: (response) => {
            resolve(response); 
          },
          error: (error) => {
            reject(error); 
          },
        });
      });
  
      
      dispatch(receiveSignIn(res)); 
      dispatch(setAuthToken(res)); 
      await dispatch(fetchGetUserInfo());
      
    } catch (error) {
      console.error('Error during sign-in:', error);
      dispatch(errorSignIn(error));
    }
  };
    

export const setCurrentDialog = (uuid) => ({
  type: A.SET_CURRENT_DIALOG,
  uuid,
});

export const getDepartaments = (uuidCompany) => (dispatch) => {
  wsSndMessage({
    action: 'chat.departments.get',
    data: {
      uuidCompany,
    },
  })(dispatch);
};

export const updateDepartments = (payload) => (dispatch) =>
  dispatch({
    type: A.UPDATE_DEPARTMENTS,
    payload,
  });

export const assignDialog = (payload) => (dispatch) => {
  const { uuidCompany, uuidDialog, uuidAssignee } = payload;
  wsSndMessage({
    action: 'chat.dialog.assign',
    data: {
      uuidCompany,
      uuidDialog,
      uuidAssignee,
    },
  })(dispatch);
};

export const updateUserEmail = (body) => (dispatch, getState) => {
  console.log('UPDATE ', body);
  apiPost({
    dispatch,
    url: `${HOST}/legacy/email`,
    tokens: getState().session.tokenData,
    body,
    callback: () => {
      dispatch(emailUpdate(body.email));
    },
  });
};
export const updateUserPassword = (body) => (dispatch, getState) => {
  apiPost({
    dispatch,
    url: `${HOST}/legacy/password`,
    tokens: getState().session.tokenData,
    body,
    callback: () => {
      // console.log('password changed is working');
    },
  });
};
