import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getSocketInstance } from "../../../../store/middleware/ws"
import { useParams } from 'react-router-dom';
import { getUsetInStoplist } from '../../../../store/actions/personActions'
import * as selector from 'store/selectors';

import styled from 'styled-components';
import { DebugView } from 'components';
import { DEFAULT_DIALOG_MESSAGES_PAGE_SIZE, PROVIDERS_CAN_TAG } from 'config';
import {
  chatClearNewMessagesState,
  getChatDialog,
  getChatMessages,
  postChatAttachmentThroughBody,
  removeNewAssignDialog,
  setDialogRead,
  setDialogOpened,
  setMessagesGetLoadingState,
} from 'store/actions/chatActions';
import { setCurrentDialog } from 'store/actions/sessionActions';
import { setCurrentMessageId } from 'store/actions/searchActions';
import { ReactComponent as Ellipsis } from '../../../../assets/img/ellipsis.svg';
import {
  ConversationForm,
  ConversationMessages,
  ConversationPreview,
  ConversationToolbar,
} from './components';
import DialogTags from './components/DialogTags';
import UsersTag from './components/UsersTag';
import _ from 'lodash';

export const useConversationDetailsStyles = makeStyles((theme) => ({
  root: {
    outline: 'none',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.white,
    flexGrow: 1,
    marginRight: (rightPanel) => (rightPanel.open ? '332px' : '0px'),
    transition: 'margin-right 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '@media (max-width: 960px)': {
      height: '100%',
      flexBasis: '100%',
      width: '100%',
      flexShrink: '0',
      transform: 'translateX(-100%)',
    },
  },
  divider: {
    height: '2px',
  },
}));

const DropzoneWrapper = styled(Grid)`
  position: absolute;
  height: 100%;
  border: 2px dashed #a0a8bb;
  background-color: #fff;
`;

const DropzoneElement = styled(Grid)`
  padding: 100px;

  text-align: center;
`;

export const ConversationMessagesWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  height: 100%;
`;

const Typing = styled.div`
  background: #ebeef3a3;
  position: absolute;
  bottom: 0;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #383838;
  font-size: 13px;
  width: 100%;
  font-style: italic;
  .typing {
    gap: 5px;
    display: flex;
    align-items: center;
  }
`;

const ConversationDetails = () => {
  const { t } = useTranslation();
  const rightPanel = useSelector((state) => state.rightPanel);
  const user = useSelector((state) => state.session.user);
  const classes = useConversationDetailsStyles(rightPanel);
  const dispatch = useDispatch();
  const routerParams = useParams();
  const { messagesIsLoading, isDebug, selectedMsg, message, typing } =
    useSelector((state) => state.chat);
  const [scrollPosition, setScrollPosition] = useState({});
  const [shouldGoTo, setShouldGoTo] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [mentionUsers, setMentionUsers] = useState([]);
  const searchState = useSelector((state) => state.search);
  const uuidCompany = useSelector((state) => state.session.selectedCompany);
  const dialogsPagePagination = useSelector(state => state.chat.dialogsPagination?.page);
  const [isDialogLoadedByUuid, setIsDialogLoadedByUuid] = useState(false)
  const store = useStore();
  const socket = getSocketInstance();
  const userInStopList = useSelector((state) => state.person.inStopList);
  // const conversation = useSelector((state) => (searchState.searchModeIsOn ? searchState.currentDialog : state.chat.dialogs.find((d) => d.uuid === routerParams.uuid) || null));
  const searchedDialogs = useSelector((state) => state.chat.searchedDialogs);
  const conversation =
    useSelector(
      (state) =>
        state.chat.dialogs.find((d) => d.uuid === routerParams.uuid) || null,
    ) ||
    searchedDialogs.find((d) => d.uuid === routerParams.uuid) ||
    null;
  const messageList = useRef(null);
  const typingUsers =
    conversation && conversation.uuid
      ? typing.filter(
        ({ uuidDialog, uuidUser }) =>
          conversation.uuid === uuidDialog && uuidUser !== user?.uuid,
      )
      : [];

  useEffect(() => {
    if (conversation && conversation.persone && conversation.type !== 'group') {
      const { persone, ids: { uuidMessengerUser } } = conversation;
      if (uuidMessengerUser) {
        dispatch(getUsetInStoplist(uuidMessengerUser, persone.ids.uuidCompany));
      }
    }
  }, [conversation]);

  useEffect(() => {
    const handleReconnect = () => {
      if (conversation && conversation.uuid) {
        setLoading(true);
        dispatch(
          getChatMessages({
            uuidDialog: conversation.uuid,
            first: true,
          })
        ).then(() => {
          setLoading(false);
        });
      }
    };

    if (socket && socket instanceof WebSocket) {
      socket.addEventListener('open', handleReconnect);
    }

    return () => {
      if (socket && socket instanceof WebSocket) {
        socket.removeEventListener('open', handleReconnect);
      }
    };
  }, [conversation, socket]);

  useEffect(() => {
    if (conversation && conversation.historyPagination) {
      const { size, page, count } = conversation.historyPagination;
      if (size === 1 && page === 1 && count !== 1) {
        dispatch(
          getChatMessages({ uuidDialog: conversation.uuid, first: true }),
        );
      }
      if (conversation.first && messageList && messageList.current) {
        const {
          current: { scrollHeight },
        } = messageList;
        messageList.current.scrollTop = scrollHeight;
      }
    }

  }, [conversation]);

  useEffect(() => {
    setLoading(true);

    if (dialogsPagePagination === 1 && !isDialogLoadedByUuid && !conversation) {
      dispatch(getChatDialog({
        uuidDialog: routerParams.uuid,
        sort: 'desc',
        historySize: DEFAULT_DIALOG_MESSAGES_PAGE_SIZE,
      }));

      setIsDialogLoadedByUuid(true);
    }

    dispatch(setCurrentDialog(routerParams.uuid));
  }, [routerParams.uuid, dialogsPagePagination]);
  useEffect(() => {
    setIsDialogLoadedByUuid(false);
  }, [routerParams.uuid])

  const handleClearNewMessagesState = () => {
    dispatch(chatClearNewMessagesState(conversation.uuid));
  };

  useEffect(() => {
    if (conversation && isLoading) {
      setLoading(false);

      setDialogRead({ uuid: conversation.uuid })(dispatch);

      if (_.get(conversation, 'additional.unopened.value', true)) {
        setDialogOpened({ uuid: conversation.uuid })(dispatch);
      }

      dispatch(removeNewAssignDialog({ uuid: conversation.uuid }));

      if (
        searchState.searchModeIsOn &&
        searchState.currentDialog.uuid === routerParams.uuid &&
        searchState.currentMessageId
      ) {
        setShouldGoTo(searchState.currentMessageId);
        dispatch(setCurrentMessageId(null));
      }

      if (conversation.hasNew) {
        handleClearNewMessagesState();
      }
      if (!conversation.historyPagination) {
        setScrollPosition({});
      } else {
        dispatch(setMessagesGetLoadingState(false));
      }
    }
  }, [conversation, isLoading]);

  const hasNew = conversation ? conversation.hasNew : false;
  useEffect(() => {
    if (hasNew) {
      // setShouldGoDown(true);
      setShouldGoTo(null);
    }
  }, [hasNew]);

  const getNewMessages = () => {
    if (!messagesIsLoading) {
      const reqObj = {
        uuidDialog: conversation.uuid,
      };

      if (conversation.historyPagination) {
        const newPage = conversation.historyPagination.page + 1;
        if (
          newPage * DEFAULT_DIALOG_MESSAGES_PAGE_SIZE -
          conversation.historyPagination.count >
          DEFAULT_DIALOG_MESSAGES_PAGE_SIZE
        ) {
          return;
        }
        reqObj.page = newPage;
      }

      dispatch(getChatMessages(reqObj));
    }
  };
  const sortedMessages = !isLoading && conversation ? conversation.history : [];

  const lastMessage = sortedMessages.length
    ? sortedMessages[sortedMessages.length - 1]
    : null;
  const handleScroll = (data) => {
    if (data.target.scrollTop === 0) {
      setScrollPosition({
        scrollTop: data.target.scrollTop,
        scrollHeight: data.target.scrollHeight,
      });
      getNewMessages();
    }
  };
  const onDrop = React.useCallback((acceptedFiles) => {
    const file = acceptedFiles?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      postChatAttachmentThroughBody(
        file.name,
        formData,
        uuidCompany,
      )(dispatch, store.getState);
    }
  }, []);
  const { getRootProps, isDragActive } = useDropzone({ onDrop });

  if (isLoading || !conversation) return null;

  return (
    <div className={classes.root} {...getRootProps()}>
      <ConversationToolbar
        conversation={conversation}
        lastMessage={lastMessage}
      />
      <Divider />

      {!(isDebug.persone || isDebug.dialog) && (
        <ConversationMessagesWrapper>
          <ConversationMessages
            currentPersone={conversation.persone}
            participants={conversation.participants}
            messageList={messageList}
            conversationId={conversation.uuid}
            sortedMessages={sortedMessages}
            provider={conversation?.provider?.messenger}
            pagination={conversation.historyPagination || {}}
            scrollPosition={scrollPosition}
            shouldGoTo={shouldGoTo}
            setShouldGoTo={setShouldGoTo}
            handleClearNewMessagesState={handleClearNewMessagesState}
            handleScroll={handleScroll}
          />
          {typingUsers && typingUsers.length > 0 && (
            <Typing>
              <Ellipsis />
              <div className="typing">
                {typingUsers.map(({ name }) => name).join(', ')}{' '}
                {t('chatMessages.typing')}
              </div>
            </Typing>
          )}
        </ConversationMessagesWrapper>
      )}

      {isDebug.persone && (
        <DebugView src={conversation.persone} title="Debug persone" />
      )}
      {isDebug.dialog && (
        <DebugView
          src={{ ...conversation, history: [] }}
          title="Debug dialog"
        />
      )}
      {isDragActive && (
        <DropzoneWrapper container justify="center" alignItems="center">
          <DropzoneElement>
            <Grid container justify="center" alignItems="stretch">
              <Grid item xs={12}>
                <Typography variant="subtitle1">Файл для загрузки</Typography>
              </Grid>
            </Grid>
          </DropzoneElement>
        </DropzoneWrapper>
      )}

      <ConversationPreview />
      {!selectedMsg.length > 0 && <DialogTags conversation={conversation} />}
      {PROVIDERS_CAN_TAG.includes(conversation.provider.messenger) &&
        conversation.type === 'group' &&
        message.endsWith('@') && (
          <UsersTag
            setMentionUsers={(users) => setMentionUsers(users)}
            conversation={conversation}
          />
        )}
      <ConversationForm
        conversation={conversation}
        mentionUsers={mentionUsers}
        userInStopList={userInStopList}
        lastMessage={lastMessage}
      />
    </div>
  );
};

export default ConversationDetails;
