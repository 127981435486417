import { apiPost, apiGet } from 'api';
import { HOST } from '../../config';
export const SET_MY_SCHEDULE = 'SET_MY_SCHEDULE';
export const ADD_TIME_TO_WEEKDAY = 'ADD_TIME_TO_WEEKDAY';
export const REMOVE_TIME_FROM_WEEKDAY = 'REMOVE_TIME_FROM_WEEKDAY';
export const UPDATE_WEEKDAY_STATE = 'UPDATE_WEEKDAY_STATE';
export const CHANGE_ISEDITED_STATUS = 'CHANGE_ISEDITED_STATUS';
export const CHANGE_GMT = 'CHANGE_GMT';

export const setMySchedule = (payload) => ({
  type: SET_MY_SCHEDULE,
  payload,
});
export const addTimeToWeekday = (weekday, time) => ({
  type: ADD_TIME_TO_WEEKDAY,
  payload: { weekday, time },
});
export const removeTimeFromWeekday = (weekday, index) => ({
  type: REMOVE_TIME_FROM_WEEKDAY,
  payload: { weekday, index },
});
export const updateWeekdayState = (weekday, state) => ({
  type: UPDATE_WEEKDAY_STATE,
  payload: { weekday, state },
});
export const changeIsEditedStatus = (payload) => ({
  type: CHANGE_ISEDITED_STATUS,
  payload,
});
export const changeGMT = (payload) => ({
  type: CHANGE_GMT,
  payload,
});

export const fetchMyScheduleTimes = () => async (dispatch, getState) => {
  apiGet({
    dispatch,
    url: `${HOST}/legacy/schedule`,
    tokens: getState().session.tokenData,
    callback: (res) => {
      if (Object.keys(res).length === 0) {
        return
      }

      dispatch(setMySchedule(res));
    },
  });
};

export const updateMyScheduleTimes =
  ({ schedule }) =>
    async (dispatch, getState) => {
      apiPost({
        dispatch,
        url: `${HOST}/legacy/schedule`,
        tokens: getState().session.tokenData,
        body: schedule,
        callback: (res) => {
          if (res.success === true) {
            dispatch(changeIsEditedStatus(false));
          }
        },
      });
    };
