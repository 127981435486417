import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { TextField } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch } from 'react-redux';
import {
  changeGMT,
  changeIsEditedStatus,
} from 'store/actions/myScheduleActions';

interface IOption {
  value: string;
  label: string;
}

const TimeZoneSelect = ({ timezone }: { timezone: string }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [timezones, setTimezones] = useState<IOption[]>([]);

  useEffect(() => {
    const loadTimezones = () => {
      const allTimezones = moment.tz.names();

      // Автоматическая фильтрация часовых поясов, содержащих ключевые регионы
      const filteredTimezones = allTimezones.filter(
        (tz) =>
          tz.startsWith('Europe/') || // Европа (для России и соседей)
          tz.startsWith('Asia/') // Азия (для стран СНГ)
      );

      const timezoneObjects: IOption[] = filteredTimezones.map((tz) => ({
        value: tz,
        label: formatTimezoneName(tz),
      }));

      setTimezones(timezoneObjects);
    };

    loadTimezones();
  }, []);

  return (
    <Autocomplete
      disablePortal
      className={classes.autocomplete}
      options={timezones}
      value={
        timezone
          ? { value: timezone, label: formatTimezoneName(timezone) }
          : null
      }
      renderInput={(params) => <TextField {...params} />}
      onChange={(_, val) => {
        if (val?.value) {
          dispatch(changeGMT(val.value));
          dispatch(changeIsEditedStatus(true));
        }
      }}
      isOptionEqualToValue={(option, selected) =>
        option.value === selected.value
      }
    />
  );
};
export default TimeZoneSelect;

const useStyles = makeStyles({
  autocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      height: '40px',
      borderRadius: '8px',
      padding: '10px 16px !important',
      marginBottom: '40px',
    },

    '& .MuiInputBase-input': {
      padding: '0 !important',
    },
    ' & .MuiOutlinedInput-root': {
      padding: 0,
    },
  },
});

export function formatTimezoneName(timezone: string) {
  const cityName = timezone
    .substring(timezone.lastIndexOf('/') + 1)
    .replace(/_/g, ' ');
  const currentTime = moment().tz(timezone).format('HH:mm');
  const offset = moment().tz(timezone).format('Z');

  return `GMT${offset} (${cityName}) / ${currentTime}`;
}
